import * as React from "react";
import Layout from "../components/Layout.js";
import HeroComponent from "../components/hero/index.js";

const Wundar = () => {
    return (
      <Layout>
           <HeroComponent
            page={"wundar"}
            heading={"Wundar"}
            subheading={"Wundar is a private network connecting COMM-STEM members to industry leading mentors, jobs and events. It is a super easy to use and supportive platform. "}
      ></HeroComponent>
         
      </Layout>

      );

};

export default Wundar;
