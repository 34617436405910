import * as React from "react";
import HomeImage from "../../images/Home.png";
import AboutImage from "../../images/About.png";
import EventsImage from "../../images/Events.png";
import wundar from '../../images/wundar.png'
import SignUp from "./signup";


const HeroComponent = ({
  page,
  heading,
  subheading,
  formText,
  formCTA,
  punctuation,
}) => {
  return (
    <div className="flex lg:flex-row flex-col items-center justify-center px-10 py-5 sm:py-10 md:px-10 md:pb-20 lg:px-24 xl:px-32">
      <div className="flex flex-col lg:w-7/12 w-auto md:px-10 lg:px-0 lg:pr-20 text-center lg:text-left">
        <h1 className="leading-snug text-3xl md:text-5xl xl:text-6xl xl:leading-snug font-bold pb-8 md:pb-12 font-body last:text-skyBlue">
          {heading}
          <span className="text-skyBlue font-bold">{punctuation}</span>
        </h1>
        <h2 className="text-lg xl:text-2xl font-lexend-deca pb-8 md:pb-12">
          {subheading}
        </h2>
        {formText && formCTA && (
          <SignUp width={"w-[60%]"} loc={"start"}></SignUp>
        )}
      </div>
      <img
        src={
          page === "Home"
            ? HomeImage
            : page === "About"
            ? AboutImage
            : page == "wundar"
            ? wundar
            : EventsImage
        }
        className="w-8/12 md:w-7/12 lg:w-5/12 pt-16 md:pt-10 lg:pt-0"
      ></img>
    </div>
  );
};

export default HeroComponent;
