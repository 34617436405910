import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import NavBar from "./navbar";

export default function Layout({ children }) {
  return (
    <div className="layout">
      <NavBar />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
}
