import * as React from "react";

const SignUp = ({ width, loc }) => {
  const [email, setEmail] = React.useState("");

  return (
    <form className={`w-[100%] md:${width} flex flex-row justify-${loc} `}>
      <div
        className={`flex flex-row justify-between font-body border-2 border-black rounded-full w-[100%] lg:${width} pl-5 pr-2 py-1`}
      >
        <input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email to be a member"
          value={email}
          type="text"
          name="title"
          id="title"
          className="w-3/4 md:w-5/6 py-2 flex-grow-2 focus:outline-none text-xs sm:text-base"
        />
        <a
          target="_blank"
          href={`https://docs.google.com/forms/d/e/1FAIpQLScrJ9PMpFVIwvPpkBUAh-xo3Rr2riUuCXDAoHwRVd1KZZMVQA/viewform?usp=pp_url&entry.1833469963=${email}`}
          className="inline-flex focus:outline-0 items-center px-3 py-1.5 border border-transparent w-[85px] sm:w-[90px] text-xs sm:text-sm font-medium font-lexend-deca rounded-full shadow-sm text-white bg-blue hover:bg-blue-600"
        >
          SIGN UP
        </a>
      </div>
    </form>
  );
};

export default SignUp;
